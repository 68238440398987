











import Vue from 'vue';
import Component from 'vue-class-component';
import { Output } from '@/models/data/models';

@Component({})
export default class OutputList extends Vue {
  Output = Output;
  get filter() {
    return {
      application: this.$store.getters['global/application']
        ? this.$store.getters['global/application'].id
        : 'unknown', // explicitly set to 'unknown' to not show anything
    };
  }

  get collectionFilter() {
    return {
      order_by: 'handle',
    };
  }
}
